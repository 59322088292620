<template>
  
  <b-container fluid>
    <h4>{{ $t('admin.trm.title') }}</h4>
    <b-row class="my-5 align-items-center" v-if="configRol.administrador.pestana.trm.acciones.editar">
      <b-col sm="2">
        <label :for="`type-number`"><b>{{ $t('admin.trm.value') }}</b>:</label>
      </b-col>
      <b-col sm="4">
        <b-form-input
         :id="`type-number`"
         step='0.01'
         type="number"
         v-model="numberInput"
         :disabled="!editTRM"
         @blur="blurValidation"
        ></b-form-input>
      </b-col>
      <b-col >
          <b-button class="button btn-blue-dark" @click="editTRM = !editTRM">
              <b-icon icon="pencil-square" aria-hidden="true" class="mr-2"></b-icon>
              {{ $t('admin.trm.edit') }}
          </b-button>
          <b-button class="button btn-blue-dark ml-3 " @click="updateTRM" :disabled="isValid || isLoading">
            <template v-if="!isLoading">{{ $t('admin.trm.save') }}</template>
            <template v-else>{{ $t('admin.trm.saving') }}... </template>
          </b-button>
      </b-col>
     
    </b-row>
    <b-row v-if="configRol.administrador.pestana.trm.acciones.listar">
        <h4 class="my-4">{{ $t('admin.trm.history') }}</h4>
        <b-table 
          hover
          :items="items"
          :fields="fields"
          :busy="isBusy"
          sort-icon-left
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(createdAt)="row">
            {{ getDate(row.value) }}
          </template>
        </b-table>
    </b-row>
    <b-row v-else>
         <b-alert show dismissible>
          <p>{{ $t('permissions.msgPermissionsTrm') }}</p>
        </b-alert>
      </b-row>
  </b-container>


</template>

<script>
import { API_ROOT } from '../../Constants/Api';
import { AlertForm, ICON } from '../../helpers/Alert';
import {DateFullDay} from '../../helpers/Date';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: 'TRM',
  components: {},
  data: () => ({
      editTRM: false,
      numberInput: 0,
      items: [],
      isBusy: false,
      refNumber: 0,
      isLoading: false,
      configRol: {}
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.trm.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.trm.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  computed: {
    fields() {
      return  [
        { key: "mxp", label: "TRM", sortable: true, sortDirection: "desc" },
        { key: "creador", label: this.$i18n.t('admin.trm.responsible'),sortable: true, sortDirection: "desc" },
        { key: "createdAt", label: this.$i18n.t('admin.trm.createAt'), sortable: true, sortDirection: "desc" },
      ]
    },
    isValid() {
      return this.numberInput == this.refNumber;
    },
    
  },
  
  mounted() {
    this.getTRM();
  },
  
  methods: {
    async getTRM(){
      try {
        this.isBusy = true;
        const data = await ApiRequest.get(`${API_ROOT}tasaTRM`);
        this.items = data.trms;
        this.numberInput = (data.trms.length) ? data.trms[0].mxp: '';
        this.refNumber = this.numberInput;
        this.isBusy = false;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },

    async updateTRM(){
      this.isLoading= true;

      try {
        await ApiRequest.post(`${API_ROOT}tasaTRM`, {
          trm_mxp: this.numberInput,
          correo_adm : this.$store.state.userResponse.email
        })
        AlertForm( `${this.$i18n.t('admin.trm.valueUpdate')}: ${this.numberInput}`, "" , ICON.SUCCESS, 'center');
        this.getTRM();
        this.editTRM = false;
        this.isLoading= false;
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },
    getDate(date) {
      return DateFullDay(date, this.$i18n.locale);
    },
    blurValidation() {
      this.numberInput = Number.parseFloat(this.numberInput).toFixed(2);
    }
  }
}
</script>

<style>

</style>