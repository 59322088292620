var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("admin.trm.title")))]),
      _vm.configRol.administrador.pestana.trm.acciones.editar
        ? _c(
            "b-row",
            { staticClass: "my-5 align-items-center" },
            [
              _c("b-col", { attrs: { sm: "2" } }, [
                _c("label", { attrs: { for: "type-number" } }, [
                  _c("b", [_vm._v(_vm._s(_vm.$t("admin.trm.value")))]),
                  _vm._v(":")
                ])
              ]),
              _c(
                "b-col",
                { attrs: { sm: "4" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "type-number",
                      step: "0.01",
                      type: "number",
                      disabled: !_vm.editTRM
                    },
                    on: { blur: _vm.blurValidation },
                    model: {
                      value: _vm.numberInput,
                      callback: function($$v) {
                        _vm.numberInput = $$v
                      },
                      expression: "numberInput"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button btn-blue-dark",
                      on: {
                        click: function($event) {
                          _vm.editTRM = !_vm.editTRM
                        }
                      }
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2",
                        attrs: { icon: "pencil-square", "aria-hidden": "true" }
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("admin.trm.edit")) + " ")
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "button btn-blue-dark ml-3 ",
                      attrs: { disabled: _vm.isValid || _vm.isLoading },
                      on: { click: _vm.updateTRM }
                    },
                    [
                      !_vm.isLoading
                        ? [_vm._v(_vm._s(_vm.$t("admin.trm.save")))]
                        : [_vm._v(_vm._s(_vm.$t("admin.trm.saving")) + "... ")]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.configRol.administrador.pestana.trm.acciones.listar
        ? _c(
            "b-row",
            [
              _c("h4", { staticClass: "my-4" }, [
                _vm._v(_vm._s(_vm.$t("admin.trm.history")))
              ]),
              _c("b-table", {
                attrs: {
                  hover: "",
                  items: _vm.items,
                  fields: _vm.fields,
                  busy: _vm.isBusy,
                  "sort-icon-left": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-primary my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(createdAt)",
                      fn: function(row) {
                        return [
                          _vm._v(" " + _vm._s(_vm.getDate(row.value)) + " ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1172731789
                )
              })
            ],
            1
          )
        : _c(
            "b-row",
            [
              _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("permissions.msgPermissionsTrm")))
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }